<template>
  <main>

    <b-modal id="modalcheckTr" v-model="formModal" ok-only centered size="lg">
      <p class="lead text-success">{{$t('message.ttvs')}}</p>
    </b-modal>

    <div class="container-fluid">
      <b-jumbotron id="jumbotron" border-variant="dark" header-level="5">
        <template #header>{{$t('message.affiliate_program_join')}}</template>
        <template #lead>
          {{$t('message.affiliate_program_byjoin')}}
        </template>
      </b-jumbotron>
    </div>

    <div id="ContactContainer" class="container-fluid">
        <!-- Content Row -->
      <b-row class="mb-4">
          <b-col cols="12" class="pt-1">
            <b-row class="pt-1">
              <b-col cols="12" class="text-center">
                <h2 class="savetravellocdetails">{{$t('message.company_info')}}</h2>
                <h6 class="detsafspan">{{$t('message.sft_contact_ifcontusoon')}}</h6>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="mt-1">
            <!-- Contact Form -->
            <b-row class="justify-content-center">
              <b-col  
                  xl="6"
                  lg="8"
                  md="10"
                  sm="12">
                <form @submit.prevent="submitForm">
                  <div class="control-group form-group">
                    <div class="controls">
                      <label>{{$t('message.company_name')}}:</label>
                      <input type="text" class="form-control" name="company_name" v-model="formData.company_name" minlength="4" required :placeholder='$t(`message.company_name_placeholder`)'>
                    </div>
                  </div>
                  <div class="control-group form-group">
                    <div class="controls">
                      <label>{{$t('message.company_email')}}</label>
                      <input type="email" class="form-control" name="company_email" v-model="formData.company_email" minlength="10" required :placeholder='$t(`message.sft_contact_placehomail`)'>
                    </div>
                  </div>
                  <div class="control-group form-group">
                    <div class="controls">
                      <label>{{$t('message.telephone')}}</label>
                      <input type="tel" class="form-control" name="company_phone" v-model="formData.company_phone" pattern=".{6,}" required :placeholder='$t(`message.sft_contact_placehophone`)' onkeyup="this.value=this.value.replace(/([^+0-9]+)/gi, '')">
                    </div>
                  </div>
                  <div class="control-group form-group">
                    <div class="controls">
                      <label>{{$t('message.contact_person')}}</label>
                      <input type="text" class="form-control" name="company_contact_person" v-model="formData.company_contact_person" minlength="6" required :placeholder='$t(`message.sft_contact_person_placeholder`)'>
                    </div>
                  </div>
                  <input type="text" name="_honey" style="display:none">
                  <input type="hidden" name="_captcha" value="true">
                  <button type="submit" id="submit" class="btn btn-secondary">{{$t('message.sft_contact_sentmessage')}}</button>
                </form>
              </b-col>
            </b-row>
          </b-col>
      </b-row>
    </div>
  </main>
</template>

<script>
import {
  BRow, BCol, BJumbotron, BEmbed, BTable, BCardText,
} from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    BJumbotron,
    BEmbed,
    BTable,
    BCardText,
  },
  data() {
    return {
      formModal:false,
      support_logo: require('@/assets/images/quotation/support2.png'),
      formData: {
        company_name: '',
        company_email:'',
        company_phone:'',
        company_contact_person:'',
      }
    }
  },
  methods: {
    async submitForm() {
      await fetch("https://formsubmit.co/ajax/sales@safetravel.today", {
        method: "POST",
        headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(this.formData)
      })
      .then(response => response.json())
      .then(data => {
        if(data.success) {
          Object.keys(this.formData).forEach(key => this.formData[key]='');
          this.formModal=true
        }
      })
      .catch(error => console.warn(error));
    }
  }
}
</script>